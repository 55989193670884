/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

import {
  INCREMENT_CURRENT_DATA_FRETCHING_STEP,
  INITIALIZATION_COMPLETED,
  INITIALIZATION_STARTED,
  SESSION_DATA_RETRIEVED,
  SET_TOTAL_DATA_FETCHING_STEPS,
} from './actionTypes';

// App initialization states.
const AWAITING = 'AWAITING';
const STARTED = 'STARTED';
const FETCHING_DATA = 'FETCHING_DATA';
const FINALIZING = 'FINALIZING';
const COMPLETED = 'COMPLETED';

const initialState = {
  currentDataFetchingStep: 0,
  totalDataFetchingSteps: undefined,
  // Tracks the state of loading the session data / initializing the app.
  appInitialization: AWAITING,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZATION_STARTED:
      return { ...state, appInitialization: STARTED, currentDataFetchingStep: 0 };

    case SET_TOTAL_DATA_FETCHING_STEPS:
      return {
        ...state,
        totalDataFetchingSteps: action.payload,
      };

    case INCREMENT_CURRENT_DATA_FRETCHING_STEP:
      return {
        ...state,
        appInitialization: FETCHING_DATA,
        currentDataFetchingStep: state.currentDataFetchingStep + 1,
      };

    case SESSION_DATA_RETRIEVED:
      return {
        ...state,
        appInitialization: FINALIZING,
      };

    case INITIALIZATION_COMPLETED:
      return { ...state, appInitialization: COMPLETED };

    default:
      return state;
  }
}

export const appInitialization = state => state.ui.appInitialization;


export const isAppInitializationCompleted = state =>
  state.ui.appInitialization === COMPLETED;


export const initializationProgress = (state) => {
  const currentState = state.ui.appInitialization;

  if (currentState === AWAITING || currentState === STARTED) {
    return 0;
  }
  if (currentState === FINALIZING || currentState === COMPLETED) {
    return 100;
  }

  const totalSteps = state.ui.totalDataFetchingSteps;
  const currentStep = state.ui.currentDataFetchingStep;
  const progress = (currentStep / (totalSteps + 1)) * 100;

  return progress;
};
