import React from 'react';
import ReactDOM from 'react-dom';

import { AccountMenu, ModalContainer } from 'flight-account-menu';
import 'flight-account-menu/dist/index.css';

ReactDOM.render(
  <AccountMenu 
    signedInLinks={[
      {
        href: "https://center.alces-flight.com",
        text: "Flight Center",
      }
    ]}
  />,
  document.getElementById('flight-account-menu')
);
ReactDOM.render(<ModalContainer />, document.getElementById('flight-account-modal-container'));
