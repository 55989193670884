/*=============================================================================
 * Copyright (C) 2016-2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Waypoint from 'react-waypoint';
import cx from 'classnames';
import styled from 'styled-components';
import { ListGroup } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { packageGroupsSelector } from '../selectors';
import { packageGroupingShape } from '../utils/propTypes';
import PackageGroupSelectListItem from './PackageGroupSelectListItem';
import * as actions from '../modules/search/actions';

class PackageGroupSelectList extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    groupings: PropTypes.arrayOf(
      PropTypes.shape(packageGroupingShape).isRequired,
    ).isRequired,
    onScrolledToEnd: PropTypes.func,
    packageGroupFilter: PropTypes.string,
    setPackageGroupFilter: PropTypes.func.isRequired,
  };

  setScrollbarRef = (ref) => {
    // We now have a scrollbar ref.  We need to render again so that the
    // waypoint can be added.
    this.scrollbar = ref;
    this.forceUpdate();
  };

  renderWaypoint() {
    if (this.scrollbar == null) {
      return null;
    }
    if (this.props.onScrolledToEnd == null) {
      return null;
    }

    return (
      <Waypoint
        onEnter={() => this.props.onScrolledToEnd(true)}
        onLeave={() => this.props.onScrolledToEnd(false)}
        scrollableAncestor={this.scrollbar._container}
      />
    );
  }

  render() {
    const {
      className,
      groupings,
      packageGroupFilter,
      setPackageGroupFilter,
    } = this.props;

    return (
      <div className={cx(className, "flight-packageGroupSelectList")}>
        <PerfectScrollbar ref={this.setScrollbarRef}>
          <ListGroup>
            {
              groupings.map(grouping => (
                <PackageGroupSelectListItem
                  active={packageGroupFilter === grouping.id}
                  id={grouping.id}
                  key={grouping.name}
                  name={grouping.name}
                  setPackageGroupFilter={setPackageGroupFilter}
                />
              ))
            }
          </ListGroup>
          {this.renderWaypoint()}
        </PerfectScrollbar>
      </div>
    );
  }
}

const withStyles = (...args) => (Component) => styled(Component)(...args);
const enhance = compose(
  connect(
    packageGroupsSelector,
    {
      setPackageGroupFilter: actions.setPackageGroupFilter,
    },
  ),

  withStyles`
    margin-top: 15px;
  `,
);

export default enhance(PackageGroupSelectList);
