/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import mkDebug from 'debug';

import Scrollbar from './Scrollbar';

const debug = mkDebug('LazilyRenderChildren');
let debugId = 0;
const nextDebugId = () => {
  debugId += 1;
  return debugId;
};

class LazilyRenderChildren extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.debugId = nextDebugId();
    debug(`${this.debugId} New instance created`);

    this.state = {
      isResetting: false,
      startIndex: 0,
      endIndex: props.initialChildren,
    };

    this.loadMoreItems = this.loadMoreItems.bind(this);
    this.debugPositionChange = ({ currentPosition, previousPosition }) => {
      debug(`${this.debugId} Waypoint position changed. prev: ${previousPosition} cur: ${currentPosition}`);
    };
  }

  componentDidMount() {
    this.myIsMounted = true;
    // We now have a Scrollbar ref.  We need to render again so that the
    // waypoint can be added.
    setTimeout(() => { if (this.myIsMounted) { this.forceUpdate(); } }, 0);
  }

  componentWillReceiveProps(nextProps) {
    const needsReset = this.props.resetPredicate(this.props, nextProps);
    if (needsReset) {
      debug(`${this.debugId} Needs reset`);
      this.setState({
        endIndex: nextProps.initialChildren,
        isResetting: true,
      });
      setTimeout(() => {
        if (this.myIsMounted) {
          this.setState({ isResetting: false }, () => {
            setTimeout(() => {
              if (this.myIsMounted) {
                debug(`${this.debugId} Setting scroll top`);
                this.scrollbar.setScrollTop(0);
              }
            }, 100);
          });
        }
      }, 250);
    }
  }

  componentWillUnmount() {
    this.myIsMounted = false;
    debug(`${this.debugId} Unmounted`);
  }

  loadMoreItems() {
    const endIndex = this.state.endIndex;
    const increment = this.props.increment;
    const childrenCount = React.Children.count(this.props.children);

    if (endIndex < childrenCount) {
      debug(`${this.debugId} Loading ${increment} more items. endIndex=${endIndex} childrenCount=${childrenCount}`, this.debugId);
      this.setState({
        endIndex: endIndex + increment,
      });
    }
  }

  renderSelectedChildren() {
    const { startIndex, endIndex } = this.state;
    debug(`${this.debugId} Rendering children between`, startIndex, endIndex);
    const allChildren = React.Children.toArray(this.props.children);

    const selectedChildren = allChildren.slice(startIndex, endIndex);
    return selectedChildren;
  }

  handleScrolledToEnd(atEnd) {
    if (this.props.onScrolledToEnd) {
      this.props.onScrolledToEnd(atEnd);
    }
  }

  renderWaypoint() {
    if (this.state.isResetting) {
      return null;
    }
    if (this.scrollbar == null) {
      return null;
    }

    return (
      /* eslint-disable react/jsx-handler-names */
      <div style={{ clear: 'both' }}>
        <Waypoint
          bottomOffset={this.props.bottomOffset}
          onEnter={this.loadMoreItems}
          onPositionChange={this.debugPositionChange}
          scrollableAncestor={this.scrollbar.getScrollableContainer()}
        />
        <Waypoint
          onEnter={() => this.handleScrolledToEnd(true)}
          onLeave={() => this.handleScrolledToEnd(false)}
          scrollableAncestor={this.scrollbar.getScrollableContainer()}
        />
      </div>
      /* eslint-enable react/jsx-handler-names */
    );
  }

  render() {
    return (
      <Scrollbar
        excludeScrollingInSelector={this.props.excludeScrollingInSelector}
        includeScrollingInSelector={this.props.includeScrollingInSelector}
        ref={(ps) => { this.scrollbar = ps; }}
      >
        {this.renderSelectedChildren()}
        {this.renderWaypoint()}
      </Scrollbar>
    );
  }
}

const defaultResetPredicate = (prevProps, nextProps) => (
  prevProps.children !== nextProps.children
);

LazilyRenderChildren.propTypes = {
  bottomOffset: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  excludeScrollingInSelector: PropTypes.string,
  includeScrollingInSelector: PropTypes.string,
  increment: PropTypes.number.isRequired,
  initialChildren: PropTypes.number.isRequired,
  onScrolledToEnd: PropTypes.func,
  resetPredicate: PropTypes.func.isRequired,
};

LazilyRenderChildren.defaultProps = {
  bottomOffset: -270,
  increment: 18,
  initialChildren: 0,
  resetPredicate: defaultResetPredicate,
};

export default LazilyRenderChildren;
