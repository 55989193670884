import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
} from 'reactstrap';
import { pure, shallowEqual } from 'recompose';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { MissingNotice } from 'flight-reactware';
import { push } from 'connected-react-router';

import PackageDetailModal from '../components/PackageDetailModal';
import PackageSearchInput from '../components/PackageSearchInput';
import { packagesPageSelector } from '../selectors';
import { retrievePackageDetails } from '../modules/packages/actions';
import { packageDetailShape, packageSummaryShape } from '../modules/packages/propTypes';
import PackageSummaryCardDeck from '../components/PackageSummaryCardDeck';
import PackageGroupSelectList from '../components/PackageGroupSelectList';
import SpinnerWhilstChildrenRender from '../components/SpinnerWhilstChildrenRender';
import withScrollGradient from '../containers/ScrollGradientContainer';
import { CardDeckCol, GroupListCol } from '../components/BrowsePageCols';

const childrenChangedPredicate = (prevProps, nextProps) => {
  const prevPackages = React.Children.only(prevProps.children).props.packages || [];
  const nextPackages = React.Children.only(nextProps.children).props.packages || [];

  const prevIds = prevPackages.map(pkg => pkg.id);
  const nextIds = nextPackages.map(pkg => pkg.id);

  return !shallowEqual(prevIds, nextIds);
};

const NoPackagesFound = () => (
  <MissingNotice title="No matching software found">
    <p>
      No packages matching the current filter and search term could be found.
    </p>
  </MissingNotice>
);

const PackagesOrNotFoundMessage = pure(({ onScrolledToEnd, packages }) => {
  if (packages.length) {
    return (
      <SpinnerWhilstChildrenRender
        childrenChangedPredicate={childrenChangedPredicate}
      >
        <PackageSummaryCardDeck
          onScrolledToEnd={onScrolledToEnd}
          packages={packages}
        />
      </SpinnerWhilstChildrenRender>
    );
  }
  return (
    <SpinnerWhilstChildrenRender>
      <NoPackagesFound />
    </SpinnerWhilstChildrenRender>
  );
});

PackagesOrNotFoundMessage.propTypes = {
  onScrolledToEnd: PropTypes.func,
  packages: PropTypes.arrayOf(PropTypes.shape(packageSummaryShape)).isRequired,
};


const Browse = ({
  dispatch,
  isCardDeckScrolledToEnd,
  isGroupListScrolledToEnd,
  packages,
  pkg,
  setIsCardDeckScrolledToEnd,
  setIsGroupListScrolledToEnd,
}) => {
  return (
    <div>
      <Container
        className="pb-2"
        fluid
      >
        <Row className="d-flex justify-content-center">
          <GroupListCol isScrolledToEnd={isGroupListScrolledToEnd}>
            <PackageGroupSelectList
              onScrolledToEnd={setIsGroupListScrolledToEnd}
            />
          </GroupListCol>
          <CardDeckCol isScrolledToEnd={isCardDeckScrolledToEnd} >
            <PackageSearchInput />
            <PackageDetailModal
              isOpen={pkg != null}
              pkg={pkg}
              toggle={() => dispatch(push('/software/'))}
            />
            <PackagesOrNotFoundMessage
              onScrolledToEnd={setIsCardDeckScrolledToEnd}
              packages={packages}
            />
          </CardDeckCol>
        </Row>
      </Container>
    </div>
  );
};

const enhance = compose(
  connect(packagesPageSelector),
  withScrollGradient,

  lifecycle({
    // eslint-disable-next-line object-shorthand
    componentWillMount: function componentWillMount() {
      if (this.props.pkg != null) {
        this.props.dispatch(retrievePackageDetails(this.props.pkg));
      }
    },
    // eslint-disable-next-line object-shorthand
    componentWillUpdate: function componentWillUpdate(nextProps) {
      const nextId = nextProps.pkg && nextProps.pkg.id;
      const prevId = this.props.pkg && this.props.pkg.id;
      if (nextProps.pkg != null && nextId !== prevId) {
        this.props.dispatch(retrievePackageDetails(nextProps.pkg));
      }
    },
  }),
);

Browse.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isCardDeckScrolledToEnd: PropTypes.bool.isRequired,
  isGroupListScrolledToEnd: PropTypes.bool.isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape(packageSummaryShape)).isRequired,
  pkg: PropTypes.oneOfType([
    PropTypes.shape(packageDetailShape).isRequired,
    PropTypes.shape(packageSummaryShape).isRequired,
  ]),
  setIsCardDeckScrolledToEnd: PropTypes.func.isRequired,
  setIsGroupListScrolledToEnd: PropTypes.func.isRequired,
};

export default enhance(Browse);
