/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import { jsonApi } from 'flight-reactware';

import {
  setTotalDataFetchingSteps,
  sessionDataRetrieved,
  initializationStarted,
  initializationCompleted,
  incrementCurrentDataFetchingStep,
} from '../ui/actions';
import { retrievePackages } from '../packages/actions';
import { resetOnboarding } from '../onboarding/actions';

export function retrieveSession() {
  return jsonApi.actions.loadResource({
    links: {
      self: '/api/v1/session-states/0',
    },
  });
}

function finalizeInitialization() {
  return (dispatch) => {
    dispatch(sessionDataRetrieved());
    setTimeout(() => {
      // Dispatch initializationCompleted with a delay to give time
      // for the progress bar to update.
      dispatch(initializationCompleted());
    }, 500);
  };
}

const delayPromise = (time) => (
  new Promise((resolve) => {
    setTimeout(resolve, time);
  })
);

// Run the initialization process for the client app.
//
// When this process has completed, we will have downloaded the initial state
// and be displaying an appropriate page.  Until then a "loading" progress bar
// is displayed.
export function initialize() {
  return (dispatch, getState) => {
    dispatch(initializationStarted());
    dispatch(setTotalDataFetchingSteps(2));
    dispatch(resetOnboarding());

    delayPromise(250)
      .then(() => {
        dispatch(incrementCurrentDataFetchingStep());
        Promise.all([dispatch(retrieveSession()), delayPromise(250)])
          .then(() => {
            dispatch(incrementCurrentDataFetchingStep());
            return Promise.all([dispatch(retrievePackages()), delayPromise(250)]);
          })
          .then(() => dispatch(finalizeInitialization()));
      });
  };
}
