/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import { omit } from 'lodash';

import { saveState } from '../../store/persistence';
import {
  CONNECT_ACCOUNT_MESSAGE_DISMISSED,
  RESET,
  WELCOME_MESSAGE_READ,
} from './actionTypes';

function persistingState(action) {
  return (dispatch, getState) => {
    const result = dispatch(action);

    saveState({
      onboarding: omit(getState().onboarding, 'connectAccountMessageDismissed'),
    });

    return result;
  };
}

export const dismissConnectAccountMessage = () => persistingState({
  type: CONNECT_ACCOUNT_MESSAGE_DISMISSED,
  payload: true,
});

export const setWelcomeMessageRead = () => persistingState({
  type: WELCOME_MESSAGE_READ,
  payload: true,
});

export function showWelcomeMessage() {
  return {
    type: WELCOME_MESSAGE_READ,
    payload: false,
  };
}

export const resetOnboarding = () => persistingState({
  type: RESET,
});
