/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import { jsonApi } from 'flight-reactware';

import { sessionStateSelector } from '../../selectors';

export const packageSummaryFields = [
  'group',
  'lastChanged',
  'logoUrl',
  'longTitle',
  'packageType',
  'path',
  'repo',
  'shortTitle',
  'summary',
  'version',
].join(',');

export function retrievePackages() {
  return (dispatch, getState) => {
    const sessionState = sessionStateSelector(getState());
    const action = jsonApi.actions.loadRelationship({
      source: sessionState,
      relationName: 'packages',
      params: {
        'fields[packages]': packageSummaryFields,
        'filter[repo]': 'volatile',
      },
    });

    return dispatch(action);
  };
}

export function retrievePackageDetails(pkg) {
  return (dispatch) => {
    if (jsonApi.utils.getClientMeta(pkg).loading) {
      return Promise.resolve(null);
    }
    if (jsonApi.utils.getClientMeta(pkg).detailsLoaded) {
      return Promise.resolve(pkg);
    }

    const action = jsonApi.actions.loadResource(pkg);
    const minimumDelay = 750; // milliseconds
    const dispatchPromise = dispatch(action);
    const timeoutPromise = new Promise((resolve) => {
      setTimeout(resolve, minimumDelay);
    });

    return Promise.all([
      dispatchPromise,
      timeoutPromise,
    ])
      .then(([response]) => {
        dispatch(jsonApi.actions.patchMeta(pkg, { detailsLoaded: true }));
        return response;
      });
  };
}
