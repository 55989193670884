/*=============================================================================
 * Copyright (C) 2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import { createSelector } from 'reselect';
import { find } from 'lodash';

import { flagsState } from './rawStateSelectors';
import { currentAccountSelector } from './miscSelectors';

const flagByNameSelector = flagName => createSelector(
  flagsState,

  allFlags => find(allFlags, flag => flag.attributes.name === flagName),
);


export const canAccessVolatileRepoFlagSelector =
  flagByNameSelector('CAN_ACCESS_VOLATILE_REPO');


export const canAccessVolatileRepoSelector = createSelector(
  currentAccountSelector,

  account => account == null || account.meta.flags.includes('CAN_ACCESS_VOLATILE_REPO'),
);
