/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

import {
  CLUSTER_SEARCH_TERM_SET,
  DEPOT_SEARCH_TERM_SET, PACKAGE_GROUP_FILTER_SET, PACKAGE_SEARCH_TERM_SET,
} from './actionTypes';

export function setPackageGroupFilter(group) {
  return {
    type: PACKAGE_GROUP_FILTER_SET,
    payload: group,
  };
}

export function setPackageSearchTerm(term) {
  return {
    type: PACKAGE_SEARCH_TERM_SET,
    payload: term,
  };
}

export function setDepotSearchTerm(term) {
  return {
    type: DEPOT_SEARCH_TERM_SET,
    payload: term,
  };
}

export function setClusterSearchTerm(term) {
  return {
    type: CLUSTER_SEARCH_TERM_SET,
    payload: term,
  };
}
