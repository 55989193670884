/*=============================================================================
 * Copyright (C) 2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Search from 'react-search-input';
import styled from 'styled-components';

import * as actions from '../modules/search/actions';
import { packageSearchTermSelector } from '../selectors';

const PackageSearchInput = styled(({
  className,
  packageSearchTerm,
  setPackageSearchTerm,
}) => (
  <div className={className}>
    <Search
      className="search-input"
      onChange={setPackageSearchTerm}
      value={packageSearchTerm}
    />
  </div>
))`

.search-input {
  padding: 10px 10px;
  height: 52px;
  position: relative;
}

.search-input::before {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==');
  display: block;
  position: absolute;
  width: 15px;
  z-index: 3;
  height: 15px;
  font-size: 20px;
  top: 11px;
  left: 16px;
  line-height: 32px;
  opacity: 0.6;
}

.search-input > input {
  width: 100%;
  font-size: 18px;
  border: none;
  line-height: 22px;
  padding: 5px 10px 5px 25px;
  height: 32px;
  position: relative;
}

.search-input > input:focus {
  outline: none;
}


.search-input {
  margin: 15px 0;
  padding: 0;
  height: auto;
}
.search-input::before {
    left: 6px;
    top: 0;
}
.search-input > input {
    border: 1px solid #ddd;
    border-radius: 2px;
    height: auto;
}
.search-input > input:focus {
    outline: none;
}
`;

PackageSearchInput.propTypes = {
  packageSearchTerm: PropTypes.string.isRequired,
  setPackageSearchTerm: PropTypes.func.isRequired,
};


export default connect(
  state => ({ packageSearchTerm: packageSearchTermSelector(state) }),
  {
    setPackageSearchTerm: actions.setPackageSearchTerm,
  },
)(PackageSearchInput);
