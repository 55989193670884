import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { shallowEqual } from 'recompose';

import { packageSummariesSelector } from '../selectors';
import { packageSummaryShape } from '../modules/packages/propTypes';

export default function withScrollGradient(Wrapped) {
  class ScrollGradientContainer extends React.Component {
    static propTypes = {
      packages: PropTypes.arrayOf(PropTypes.shape(packageSummaryShape)).isRequired,
    };

    state = {
      isCardDeckScrolledToEnd: false,
      isGroupListScrolledToEnd: false,
    };

    componentWillReceiveProps(nextProps) {
      // If the set of package summary cards to show has changed, adjust the
      // isCardDeckScrolledToEnd.
      const prevIds = this.props.packages.map(pkg => pkg.id);
      const nextIds = nextProps.packages.map(pkg => pkg.id);
      if (!shallowEqual(prevIds, nextIds)) {
        this.setIsCardDeckScrolledToEnd(false);
      }
    }

    setIsCardDeckScrolledToEnd = (val) => (
      this.setState({ isCardDeckScrolledToEnd: val })
    );

    setIsGroupListScrolledToEnd = (val) => (
      this.setState({ isGroupListScrolledToEnd: val })
    );

    render() {
      return (
        <Wrapped
          {...this.props}
          isCardDeckScrolledToEnd={this.state.isCardDeckScrolledToEnd}
          isGroupListScrolledToEnd={this.state.isGroupListScrolledToEnd}
          setIsCardDeckScrolledToEnd={this.setIsCardDeckScrolledToEnd}
          setIsGroupListScrolledToEnd={this.setIsGroupListScrolledToEnd}
        />
      );
    }
  }

  const mapStateToProps = (state) => ({
    packages: packageSummariesSelector(state),
  });

  return connect(mapStateToProps)(ScrollGradientContainer);
}
