/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import mkDebug from 'debug';
import { DelaySpinner } from 'flight-reactware';

const debug = mkDebug('SpinnerWhilstChildrenRender');
let debugId = 0;
const nextDebugId = () => {
  debugId += 1;
  return debugId;
};

//
// Display a spinner whilst the children render.
//
// This component is useful to wrap children which are expensive to render (or
// add to the DOM).  It's particularly useful when its children are updated.
// Care is taken to ensure that the spinner is shown as quickly as possible,
// and the expensive children rendering and DOM updating only happens once the
// spinner is displayed.
//
// How it works:
//
// 1. First render
//
// When first rendered it displays a spinner and does NOT render the children.
// This means that there will be few DOM operations and the spinner will
// display quickly.
//
// Once the spinner has been added to the DOM, the children are rendered
// and added to the DOM without adjusting the layout of the DOM.
//
// Once the children have been added to the DOM, the spinner is hidden and the
// children shown.
//
// 2. Updating the children
//
// When the children are updated, the old children are hidden and the spinner
// is shown.  Care is taken to ensure that the old children are the ones being
// returned by the render method, that is we avoid updating the children at
// this point.
//
// Once the spinner is displayed and the old children hidden, the old children
// are removed from the DOM and the new children are rendered and added to the
// DOM.
//
// Once the new children have been added to the DOM the spinner is hidden and
// the new children shown.
//
class SpinnerWhilstChildrenRender extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.debugId = nextDebugId();
    debug(`${this.debugId} New instance created`);

    this.state = {
      childrenChanged: true,
      renderNewChildren: false,
      renderOldChildren: false,
      showSpinner: true,
      spinnerCount: 0,
    };
  }

  componentDidMount() {
    const newState = {
      renderNewChildren: true,
    };
    setTimeout(() => {
      debug(`${this.debugId} Mounted -- rerendering to include new children`);
      this.setState(newState);
    }, 250);
  }

  componentWillReceiveProps(nextProps) {
    const childrenChanged = this.props.childrenChangedPredicate(this.props, nextProps);
    if (childrenChanged) {
      debug(`${this.debugId} Children about to change -- rerendering to show spinner`);
      this.oldChildren = this.props.children;
      this.setState({
        childrenChanged: true,
        renderNewChildren: false,
        renderOldChildren: true,
        showSpinner: true,
        spinnerCount: this.state.spinnerCount + 1,
      });
    }
  }

  componentDidUpdate() {
    let newState;
    let debugExtra;

    if (this.state.childrenChanged) {
      this.oldChildren = null;
      newState = {
        childrenChanged: false,
        renderNewChildren: true,
        renderOldChildren: false,
        showSpinner: true,
      };
      debugExtra = 'to include new children and remove old';
    } else if (this.state.showSpinner) {
      newState = {
        showSpinner: false,
        spinnerCount: this.state.spinnerCount + 1,
      };
      debugExtra = 'to remove spinner';
    }

    if (newState) {
      setTimeout(() => {
        debug(`${this.debugId} Updated -- rerendering `, debugExtra);
        this.setState(newState);
      }, 250);
    }
  }

  render() {
    let childrenStyle;

    let childrenClassName;
    let spinnerClassName;
    if (this.state.showSpinner) {
      childrenStyle = { display: 'none' };
    } else {
      childrenStyle = { display: 'block' };
    }

    let children;
    if (this.state.renderOldChildren) {
      children = this.oldChildren;
    } else if (this.state.renderNewChildren) {
      children = this.props.children;
    }
    debug(
      this.debugId,
      'Rendering with state=', this.state,
      'childrenStyle=', childrenStyle,
    );

    return (
      <ReactCSSTransitionReplace
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
        transitionName="another-fade-in-out"
      >
        <div key={this.state.spinnerCount}>
          <DelaySpinner
            className={spinnerClassName}
            hide={!this.state.showSpinner}
          />
          {
            this.state.renderOldChildren || this.state.renderNewChildren
              ? (
                <div
                  className={childrenClassName}
                  style={childrenStyle}
                >
                  {children}
                </div>
              )
              : null
          }
        </div>
      </ReactCSSTransitionReplace>
    );
  }
}

const defaultChildrenChangedPredicate = (prevProps, nextProps) => (
  prevProps.children !== nextProps.children
);

SpinnerWhilstChildrenRender.defaultProps = {
  childrenChangedPredicate: defaultChildrenChangedPredicate,
};

SpinnerWhilstChildrenRender.propTypes = {
  children: PropTypes.node.isRequired,
  childrenChangedPredicate: PropTypes.func.isRequired,
};

export default SpinnerWhilstChildrenRender;
