import React from 'react';
import PropTypes from 'prop-types';
import { Container, Progress } from 'reactstrap';
import styled from 'styled-components';

const progressDescriptions = {
  AWAITING: 'Initializing',
  STARTED: 'Initializing',
  FETCHING_DATA: 'Fetching resources',
  FINALIZING: 'Starting',
  COMPLETED: 'Starting',
};

const Wrapper = styled(Container)`
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  P {
      font-size: 120%;
  }
  .progress-bar {
    transition: width 0.2s;
  }
`;

const LoadingPage = ({
  color,
  productName,
  stepDescription,
  value,
}) => (
  <Wrapper>
    <p>
      One moment please &mdash;
      {' '}<em className="nowrap">{productName}</em>{' '}
      is&nbsp;starting&nbsp;up.
    </p>
    <Progress
      className="loading-indicator-progress-bar"
      color={color}
      striped
      value={value}
    />
    {
      stepDescription
        ? <p>{progressDescriptions[stepDescription]}&hellip;</p>
        : null
    }
  </Wrapper>
);

LoadingPage.propTypes = {
  color: Progress.propTypes.color,
  productName: PropTypes.string.isRequired,
  stepDescription: PropTypes.string,
  value: PropTypes.number.isRequired,
};

LoadingPage.defaultProps = {
  color: 'info',
  value: 100,
};

export default LoadingPage;
