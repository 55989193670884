/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cx from 'classnames';

const cardDeckPadding = 12;
const cardDeckCardPadding = 12;

const CardDeck = styled(({ className, children }) => (
  <div className={cx(className, 'card-deck')}>
    {children}
  </div>
))`
    margin-left: -${cardDeckPadding / 2}px;
    margin-right: -${cardDeckPadding / 2}px;
    .card-wrapper {
        float: left;

        padding-left: ${cardDeckCardPadding / 2}px;
        padding-right: ${cardDeckCardPadding / 2}px;

        .card {
            overflow: hidden;
            margin-right: 0;
            margin-bottom: 0.75rem;
            margin-left: 0;
        }

        .card:hover {
            box-shadow: 0px 0px 8px #777;
            cursor: pointer;
        }
    }
`;

CardDeck.propTypes = {
  children: PropTypes.node,
};

export default CardDeck;
