/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import PropTypes from 'prop-types';

// Various proptypes looking for a better home.

// eslint-disable-next-line import/prefer-default-export
export const packageGroupingShape = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
};
