import styled, { css } from 'styled-components';

const screenSmMin = 622;
const screenMdMin = 986;
const screenLgMin = 1350;
const screenXlMin = 1714;
const containerSm = 622;
const containerMd = 986;
const containerLg = 1350;
const containerXl = 1714;
const packageGroupSelectListWidth = 225;

// Variables for perfect-scrollbar container heights.
const bodyPaddingTop = 123;
const footerToShow = 56;
const groupListTopMargin = 15;
const searchInputHeight = 34;
const searchInputTopMargin = 15;
const searchInputBottomMargin = 15;
const containerPaddingBottom = 8;

/* eslint-disable indent */
const gradientMixin = css`
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: ${({ isScrolledToEnd }) => isScrolledToEnd ?
        'none' :
        'linear-gradient(to bottom, rgba(255,253,246, 0), rgba(255,253,246, 1) 90%)'
        };
    width: 100%;
    height: 4em;
  }
`;
/* eslint-enable indent */

export const GroupListCol = styled.div`
  @media (min-width: ${screenSmMin}px) {
      width: ${packageGroupSelectListWidth}px;
      float: left;
  }

  ${gradientMixin}
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  cursor: pointer;

  .scrollbar-container {
    height: calc(100vh
     - ${bodyPaddingTop}px
     - ${groupListTopMargin}px
     - ${containerPaddingBottom}px
     - ${footerToShow}px
     );

     &:hover, &:active {
       .ps__scrollbar-y-rail,
       .ps__scrollbar-y,
       .ps__scrollbar-x-rail,
       .ps__scrollbar-x {
         z-index: 10;
       }
     }
  }
`;

export const CardDeckCol = styled.div `
  @media (min-width: ${screenSmMin}px) {
      width: calc( ${containerSm}px - 15rem );
      float: left;
  }

  @media (min-width: ${screenMdMin}px) {
      width: calc( ${containerMd}px - 15rem );
      float: left;
  }

  @media (min-width: ${screenLgMin}px) {
      width: calc( ${containerLg}px - 15rem );
      float: left;
  }

  @media (min-width: ${screenXlMin}px) {
      width: calc( ${containerXl}px - 15rem );
      float: left;
  }

  ${gradientMixin}
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;

  .scrollbar-container {
    height: calc(100vh
     - ${bodyPaddingTop}px
     - ${searchInputTopMargin}px
     - ${searchInputHeight}px
     - ${searchInputBottomMargin}px
     - ${containerPaddingBottom}px
     - ${footerToShow}px
     );
  }
`;
