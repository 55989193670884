/*=============================================================================
 * Copyright (C) 2016-2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

// Selectors for the onboarding module.

import { createSelector } from 'reselect';

import { onboardingState } from './rawStateSelectors';
import { currentAccountSelector } from './miscSelectors';

// eslint-disable-next-line import/prefer-default-export
export const onboardingSelector = createSelector(
  currentAccountSelector,
  onboardingState,

  (currentAccount, onboarding) => {
    let showConnectAccountMessage = true;
    if (currentAccount == null ||
        currentAccount.meta.configurationCompleted ||
        onboarding.connectAccountMessageDismissed) {
      showConnectAccountMessage = false;
    }

    return {
      ...onboarding,
      showConnectAccountMessage,
    };
  },
);
