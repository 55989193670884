/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { Button } from 'reactstrap';
import { CardOverlay, LinkContainer, TooltipTrigger } from 'flight-reactware';

const PackageSummaryCardOverlay = ({ pkg }) => (
  <CardOverlay>
    <LinkContainer
      to={{ pathname: '/software', query: { path: pkg.attributes.path } }}
    >
      <Button color="link">
        <TooltipTrigger tooltip="View details" >
          <FontAwesome
            name="info"
            size="2x"
          />
        </TooltipTrigger>
      </Button>
    </LinkContainer>
  </CardOverlay>
);

PackageSummaryCardOverlay.propTypes = {
  pkg: PropTypes.shape({
    attributes: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PackageSummaryCardOverlay;
