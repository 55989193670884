/*=============================================================================
 * Copyright (C) 2016-2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import { createSelector } from 'reselect';
import _ from 'lodash';
import { createFilter } from 'react-search-input';

import {
  packagesState,
  packageIndexes,
} from './rawStateSelectors';
import {
  packageSearchTermSelector,
  packageGroupFilterSelector,
} from './searchTermSelectors';
import { canAccessVolatileRepoSelector } from './flagSelectors';

function packageFromRouteSelector(state, props) {
  if (props.match == null) {
    return undefined;
  }
  const pathOrId = props.match.params[0];

  const pkg = packagesState(state)[pathOrId];
  if (pkg != null) {
    return pkg;
  }

  if (packageIndexes(state) == null || packageIndexes(state).path == null) {
    return undefined;
  }
  const pkgId = packageIndexes(state).path[pathOrId];
  return packagesState(state)[pkgId];
}

const filteredPackages = createSelector(
  packagesState,
  packageSearchTermSelector,
  canAccessVolatileRepoSelector,

  (unfilteredPackages, searchTerm, canAccessVolatileRepo) => {
    const includeVolatileRepo = canAccessVolatileRepo;
    const searchProps = [
      'attributes.longTitle',
      'attributes.shortTitle',
      'attributes.summary',
      'attributes.path',
    ];

    const packages = _.chain(unfilteredPackages)
      .filter(createFilter(searchTerm, searchProps))
      .filter(pkg => includeVolatileRepo || pkg.attributes.repo !== 'volatile');

    return packages;
  },
);

function latestVersion(packages) {
  return _.sortBy(packages, pkg => pkg.attributes.lastChanged).pop();
}

export const packageSummariesSelector = createSelector(
  filteredPackages,
  packageGroupFilterSelector,

  (packages, packageGroupFilter) => (
    _.chain(packages)
      .values()
      .filter(
        pkg => (packageGroupFilter ? pkg.attributes.group === packageGroupFilter : true),
      )
      .groupBy(pkg => {
        const path = pkg.attributes.path;
        const parts = path.split('/');
        parts.pop();
        const pathWithoutVersion = parts.join('/');
        return pathWithoutVersion;
      })
      .map((packages, pathWithoutVersion) => latestVersion(packages))
      .flatten()
      .sortBy(pkg => [
        pkg.attributes.shortTitle.toLowerCase(),
        pkg.attributes.repo.toLowerCase(),
        pkg.attributes.path.toLowerCase(),
      ])
      .value()
  ),
);

export const packagesPageSelector = createSelector(
  packageSummariesSelector,
  packageFromRouteSelector,

  (packages, pkg) => ({ packages, pkg }),
);

export const packageGroupsSelector = createSelector(
  filteredPackages,
  packageGroupFilterSelector,

  (packages, packageGroupFilter) => {
    const groupings = _.chain(packages)
      .values()
      .groupBy(pkg => pkg.attributes.group)
      .map((_, group) => ({ id: group, name: group }))
      .sortBy(grouping => grouping.name.toLowerCase())
      .value();

    groupings.unshift({
      id: null,
      name: 'All software',
    });

    if (groupings.findIndex(g => g.id === packageGroupFilter) === -1) {
      groupings.push({
        id: packageGroupFilter,
        name: packageGroupFilter,
      });
    }

    return {
      groupings,
      packageGroupFilter,
    };
  },
);
