import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';

import { makeMetaPages } from 'flight-reactware';

import App from './components/App';
import Browse from './pages/Browse';
import Overview from './pages/Overview';
import licenseData from './data/licenses.json';
import { icons } from './utils/depotToIcon';

const MetaPageWrapper = ({ children, title }) => (
  <div>
    <Helmet><title>{title}</title></Helmet>
    {children}
  </div>
);
MetaPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const metaPageComponents = makeMetaPages(MetaPageWrapper, {
  softwareLicenses: licenseData,
  icons: icons,
});

const metaPages = [
  {
    path: '/about',
    component: metaPageComponents.About,
    title: 'About',
  },
  {
    path: '/privacy',
    component: metaPageComponents.Privacy,
    title: 'Privacy',
  },
  {
    path: '/security',
    component: metaPageComponents.Security,
    title: 'Security',
  },
  {
    path: '/terms',
    component: metaPageComponents.Terms,
    title: 'Terms',
  },
];

const redirects = {
};
const redirectRoutes = Object.keys(redirects).map((k) => {
  const target = redirects[k];
  return {
    path: k,
    exact: true,
    component: ({ location }) => ( // eslint-disable-line react/prop-types
      <Redirect
        to={{
          pathname: target,
          // eslint-disable-next-line react/prop-types
          search: location.search,
        }}
      />
    ),
  };
});

const routes = [
  ...redirectRoutes,
  {
    component: App,
    routes: [
      ...metaPages,
      {
        path: '/',
        exact: true,
        component: Overview,
        title: 'Overview',
        pageKey: 'Overview',
        key: 'overview',
      },
      {
        path: '/software',
        exact: true,
        component: Browse,
        title: 'Software',
        pageKey: 'Library',
        key: 'home',
      },
      {
        path: '/software/*',
        component: Browse,
        title: 'Software',
        pageKey: 'Library',
        key: 'home',
      },
    ],
  },
  {
    component: metaPageComponents.NotFound,
    title: 'Not found',
  }
];

export default routes;
