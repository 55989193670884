/*=============================================================================
 * Copyright (C) 2016-2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import { createSelector } from 'reselect';
import _ from 'lodash';
import { createFilter } from 'react-search-input';

import {
  cdJobsState,
  clustersState,
  packagesState,
} from './rawStateSelectors';
import {
  clusterSearchTermSelector,
} from './searchTermSelectors';

function clusterFromRouteSelector(state, props) {
  const clusterId = props.routeParams.clusterId;
  return clustersState(state)[clusterId];
}

function pkgForCdJob(cdJob, allPackages) {
  if (cdJob && cdJob.relationships.package.data) {
    const pkgId = cdJob.relationships.package.data.id;
    return allPackages[pkgId];
  }
  return undefined;
}

function cdJobsForCluster(cluster, allCdJobs) {
  if (cluster == null) {
    return [];
  }
  if (cluster.relationships.continuousDeliveryJobs.data == null) {
    return [];
  }
  const cdJobLinks = cluster.relationships.continuousDeliveryJobs.data;
  const jobsForCluster = cdJobLinks.reduce((accum, cdJobLink) => {
    accum.push(allCdJobs[cdJobLink.id]);
    return accum;
  }, []);
  return _.orderBy(
    jobsForCluster,
    cdJob => cdJob.attributes.createdAt,
    'desc',
  );
}

function cdJobAndClusterFromRoute(state, props) {
  const cdJobId = props.location.query['cd-job'];
  const clusterId = props.routeParams.clusterId;
  return {
    cdJob: cdJobsState(state)[cdJobId],
    cluster: clustersState(state)[clusterId],
  };
}

export const filteredClustersSelector = createSelector(
  clustersState,
  clusterSearchTermSelector,

  (unfilteredClusters, searchTerm) => {
    const searchProps = [
      'attributes.title',
      'attributes.summary',
      'attributes.description',
      'attributes.status',
    ];
    const clusters = _.chain(unfilteredClusters)
      .filter(createFilter(searchTerm, searchProps))
      .sortBy(cluster => cluster.attributes.title.toLowerCase())
      .value();

    return clusters;
  },
);

export const clustersSelector = createSelector(
  clustersState,

  clusters => _.sortBy(clusters, cluster => cluster.attributes.title.toLowerCase()),
);

export const clusterAndCdJobsFromRouteSelector = createSelector(
  clusterFromRouteSelector,
  cdJobsState,

  (cluster, cdJobs) => ({
    cluster,
    cdJobs: cdJobsForCluster(cluster, cdJobs),
  }),
);

export const clusterPageSelector = createSelector(
  cdJobAndClusterFromRoute,
  cdJobsState,
  packagesState,

  ({ cdJob, cluster }, allCdJobs, allPackages) => ({
    cdJob,
    cluster,
    pkg: pkgForCdJob(cdJob, allPackages),
    cdJobs: cdJobsForCluster(cluster, allCdJobs),
  }),
);
