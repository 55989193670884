/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'recompose';

import CardDeck from './CardDeck';
import PackageSummaryCard from './PackageSummaryCard';
import { packageSummaryShape } from '../modules/packages/propTypes';
import LazilyRenderChildren from './LazilyRenderChildren';

//
// Only reset the LazilyRenderChildren component, if we are displaying a
// different set of packages.
//
// When displaying a package's details in the package detail modal, the
// package's details are downloaded, if they haven't been already, causing the
// package in the redux store to be modified (replaced).
//
// If we use the default resetPredicate, the LazilyRenderChildren component
// would be reset and the scroll position lost.
//
const resetPredicate = (prevProps, nextProps) => {
  const prevIds = React.Children.map(prevProps.children, child => child.props.pkg.id);
  const nextIds = React.Children.map(nextProps.children, child => child.props.pkg.id);
  return !shallowEqual(prevIds, nextIds);
};

const PackageSummaryCardDeck = ({ onScrolledToEnd, packages }) => (
  <CardDeck>
    <LazilyRenderChildren
      onScrolledToEnd={onScrolledToEnd}
      resetPredicate={resetPredicate}
    >
      {packages.map(pkg => (
        <PackageSummaryCard
          key={pkg.id}
          pkg={pkg}
        />
      ))}
    </LazilyRenderChildren>
  </CardDeck>
);

PackageSummaryCardDeck.propTypes = {
  onScrolledToEnd: PropTypes.func,
  packages: PropTypes.arrayOf(PropTypes.shape(packageSummaryShape)).isRequired,
};

export default PackageSummaryCardDeck;
