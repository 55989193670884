/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

export const INCREMENT_CURRENT_DATA_FRETCHING_STEP = '@prime/ui/INCREMENT_CURRENT_DATA_FRETCHING_STEP';
export const INITIALIZATION_COMPLETED = '@prime/ui/INITIALIZATION_COMPLETED';
export const INITIALIZATION_STARTED = '@prime/ui/INITIALIZATION_STARTED';
export const SESSION_DATA_RETRIEVED = '@prime/ui/SESSION_DATA_RETRIEVED';
export const SET_TOTAL_DATA_FETCHING_STEPS = '@prime/ui/SET_TOTAL_DATA_FETCHING_STEPS';
