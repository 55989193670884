/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

import {
  CLUSTER_SEARCH_TERM_SET,
  DEPOT_SEARCH_TERM_SET, PACKAGE_GROUP_FILTER_SET, PACKAGE_SEARCH_TERM_SET,
} from './actionTypes';

const initialState = {
  clusterSearchTerm: '',
  depotSearchTerm: '',
  packageGroupFilter: null,
  packageSearchTerm: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLUSTER_SEARCH_TERM_SET:
      return { ...state, clusterSearchTerm: action.payload };

    case DEPOT_SEARCH_TERM_SET:
      return { ...state, depotSearchTerm: action.payload };

    case PACKAGE_GROUP_FILTER_SET:
      return { ...state, packageGroupFilter: action.payload };

    case PACKAGE_SEARCH_TERM_SET:
      return { ...state, packageSearchTerm: action.payload };

    default:
      return state;
  }
}
