/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import { Card, CardBody, CardText } from 'reactstrap';
import { CardTitleBlock } from 'flight-reactware';
import styled from 'styled-components';

import { packageSummaryShape } from '../modules/packages/propTypes';
import FooterIcons from './PackageCardFooterIcons';
import PackageSummaryCardOverlay from './PackageSummaryCardOverlay';
import packageCardModifierClasses, { replaceWhitespace } from '../utils/packageCardClasses';
import { styles } from '../utils/packageGroupStyles';

const withStyles = (...args) => (Component) => styled(Component)(...args);

const adjustedSummary = (summary) => {
  if (summary == null) {
    return null;
  } else if (summary.endsWith('.')) {
    return summary;
  }
  return `${summary}.`;
};

class PackageSummaryCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShowDetails = this.handleShowDetails.bind(this);
  }

  handleShowDetails() {
    this.props.dispatch(push(`/software/${this.props.pkg.attributes.path}`));
  }

  render() {
    const pkg = this.props.pkg;
    const classes = cx(
      'flight-packageSummaryCard',
      this.props.className,
      packageCardModifierClasses(pkg),
    );

    return (
      <div className="card-wrapper" >
        <Card
          className={classes}
          onClick={this.handleShowDetails}
        >
          <CardBody>
            <CardTitleBlock
              logoOnRight={false}
              logoSrc={pkg.attributes.logoUrl}
              subtitle={pkg.attributes.longTitle}
              subtitleSize="medium"
              title={pkg.attributes.shortTitle}
              titleSize="large"
            />
            <CardText className="package-summary">
              {adjustedSummary(pkg.attributes.summary)}
            </CardText>
            <FooterIcons pkg={pkg} />
          </CardBody>
          <PackageSummaryCardOverlay pkg={pkg} />
        </Card>
      </div>
    );
  }
}

PackageSummaryCard.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  pkg: PropTypes.shape(packageSummaryShape).isRequired,
};

const cardWidth = 352;
const cardHeight = 279;
const linesOfSummary = 3;
const baseLineHeight = 24;

const enhance = compose(
  withStyles`
    width: ${cardWidth}px;
    height: ${cardHeight}px;

    ${(props) => styles[replaceWhitespace(props.pkg.attributes.group)]},

    .card-body {
      padding: 16px;
    }

    .package-summary {
      height: ${baseLineHeight * linesOfSummary}px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${linesOfSummary};
      -webkit-box-orient: vertical;
    }
  `,

  connect(),
);

export default enhance(PackageSummaryCard);
