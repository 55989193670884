/*=============================================================================
 * Copyright (C) 2016-2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

// Miscellaneous selectors.  When adding selectors here, consider if would be
// better off being combined with some of the other selectors here and moving
// to a "theme" selectors file.

import { createSelector } from 'reselect';
import invariant from 'invariant';

import { accountsState } from './rawStateSelectors';

export const sessionStateSelector = (state) => {
  const sessionStates = state.entities.sessionStates;
  if (sessionStates == null) {
    return undefined;
  }

  const sessionStateIds = Object.keys(sessionStates.data);
  invariant(
    sessionStateIds.length === 1,
    'There should be exactly one session state',
  );
  return sessionStates.data[sessionStateIds[0]];
};

export const currentAccountSelector = createSelector(
  accountsState,

  (accounts) => {
    const accountIds = Object.keys(accounts);
    invariant(
      accountIds.length < 2,
      'There should not be more than one account',
    );

    if (accountIds.length > 0) {
      return accounts[accountIds[0]];
    }
    return undefined;
  },
);

export const notificationsSelector = createSelector(
  state => state.notifications,

  notifications => ({
    currentModal: notifications.currentModal,
    exitingModal: notifications.exitingModal,
    infoGeneratorsMap: notifications.infoGeneratorsMap,
    errorGeneratorsMap: notifications.errorGeneratorsMap,
    showingModal: notifications.showingCurrentModal,
  }),
);
