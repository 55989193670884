import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { matchRoutes, renderRoutes } from 'react-router-config';

import {
  initializationProgress,
  appInitialization,
  isAppInitializationCompleted,
} from '../modules/ui/reducer';

import LoadingPage from './LoadingPage';
import ScrollToTop from './ScrollToTop';
import SitePage from './Page';
import PageLayout from './PageLayout';
import appVersion from '../version';
import routes from '../routes';

const productName = process.env.REACT_APP_PRODUCT_NAME;

const propTypes = {
  appInitialization: PropTypes.string.isRequired,
  initializationProgress: PropTypes.number.isRequired,
  isAppInitializationCompleted: PropTypes.bool.isRequired,
  location: PropTypes.object,
  route: PropTypes.object,
};

const App = ({
  appInitialization,
  initializationProgress,
  isAppInitializationCompleted,
  location,
  route,
}) => {
  const branch = matchRoutes(routes, location.pathname);
  const lastRouteComponent = branch[branch.length - 1].route;
  let transitionKey;
  let pageOrLoadingPage;
  if (isAppInitializationCompleted) {
    transitionKey = lastRouteComponent.key || location.key;
    pageOrLoadingPage = renderRoutes(route.routes);
  } else {
    transitionKey = 'initializing';
    pageOrLoadingPage = (
      <LoadingPage
        productName={productName}
        stepDescription={appInitialization}
        value={initializationProgress}
      />
    );
  }

  return (
    <ScrollToTop>
      <PageLayout>
        <Helmet
          defaultTitle={productName}
          titleTemplate={`${productName} - %s`}
        >
          <meta
            content={appVersion}
            name="client-version"
          />
        </Helmet>
        <SitePage
          pageKey={lastRouteComponent.pageKey}
          title={lastRouteComponent.title}
        >
          <TransitionGroup>
            <CSSTransition
              classNames="fade"
              key={transitionKey}
              timeout={{ enter: 250, exit: 250 }}
            >
              {pageOrLoadingPage}
            </CSSTransition>
          </TransitionGroup>
        </SitePage>
      </PageLayout>
    </ScrollToTop>
  );
};

App.propTypes = propTypes;

const mapStateToProps = state => ({
  initializationProgress: initializationProgress(state),
  appInitialization: appInitialization(state),
  isAppInitializationCompleted: isAppInitializationCompleted(state),
});

export default connect(mapStateToProps)(App);
