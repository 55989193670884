import { ContextLink, NavItem } from 'flight-reactware';
const { makeItem } = NavItem;
const { makeLink } = ContextLink;

const currentSite = process.env.REACT_APP_SITE;

export default [
  makeItem('Overview', 'home', makeLink(currentSite, '/')),
  makeItem('Library', 'file-archive-o', makeLink(currentSite, '/software')),
];
