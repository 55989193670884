/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import { Icon } from 'flight-reactware';
import { Link } from 'react-router-dom';

const Footer = () => (
  <Link to="/software">
    <Icon name="times" /> Close
  </Link>
);

export default Footer;
