import React from 'react';
import {
  Col,
  Container,
  Row,
} from 'reactstrap';
import { compose } from 'recompose';
import {
  Callout,
  LinkContainer,
  PageHeader,
} from 'flight-reactware';
import FontAwesome from 'react-fontawesome';

const Overview = () => (
  <div>
    <Container className="pb-2">
      <Row>
        <Col
          className="d-none d-lg-block"
          lg='4'
          xl='3'
        >
          <div style={{ marginTop: '75px', textAlign: 'center' }}>
            <FontAwesome
              fixedWidth
              name="book"
              style={{
                color: 'rgba(50,136,188,0.95)',
                fontSize: '200px'
              }}
            />
          </div>
        </Col>
        <Col>
          <PageHeader>
            Flight Gridware
          </PageHeader>
          <PageHeader.Subheader>
            Browse available applications, libraries, and compilers
          </PageHeader.Subheader>
          <PageHeader.Text>
            The Flight Gridware open-source catalogue holds hundreds of
            applications, libraries, and compilers that can be installed with
            lightning efficiency on Alces Flight HPC environments.  This site
            allows you to easily browse the software packages that are
            available for installation.  From benchmarks and bioinformatics,
            to MPIs, languages and tools we have comprehensive cover of a wide
            variety of research domains.  Have a look!
          </PageHeader.Text>
        </Col>
      </Row>
      <Callout fullWidth>
        <Callout.Text>
          I'm ready to browse Flight Gridware
        </Callout.Text>
        <LinkContainer
          to={{ pathname: '/software' }}
        >
          <Callout.Button>
            Browse
          </Callout.Button>
        </LinkContainer>
      </Callout>
    </Container>
  </div>
);

const enhance = compose();

export default enhance(Overview);
