import { compose } from 'redux';
import { combineReducers } from 'redux';
import {
  createReducers as createFlightReducers,
  jsonApi,
} from 'flight-reactware';
import { reducer as formReducer } from 'redux-form';

import onboarding from '../modules/onboarding/reducer';
import search from '../modules/search/reducer';
import ui from '../modules/ui/reducer';

const entityIndexes = [{
  entityType: 'packages',
  indexName: 'path',
  indexAttribute: entity => entity.attributes.path,
}];

export default (cookies, history) => (
  combineReducers({
    ...createFlightReducers(cookies, history),
    onboarding,
    search,
    ui,
    entities: compose(
      jsonApi.withIndexes(entityIndexes),
    )(jsonApi.reducer),
    form: formReducer,
  })
);
