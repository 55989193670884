/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

export const CONNECT_ACCOUNT_MESSAGE_DISMISSED = '@prime/onboarding/CONNECT_ACCOUNT_MESSAGE_DISMISSED';
export const RESET = '@prime/onboarding/RESET';
export const WELCOME_MESSAGE_READ = '@prime/onboarding/WELCOME_MESSAGE_READ';
