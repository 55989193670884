import React from 'react';
import { Alert, CookieBanner } from 'flight-reactware';

// eslint-disable-next-line react/prop-types
const PageLayout = ({ children }) => {
  return (
    <div>
      <Alert.Provider>
        <CookieBanner />
        {children}
      </Alert.Provider>
    </div>
  );
};

export default PageLayout;
