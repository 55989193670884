/*=============================================================================
 * Copyright (C) 2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

import DetailModalCloseButton from './DetailModalCloseButton';
import PackageDetailCard from './PackageDetailCard';
import { packageDetailShape, packageSummaryShape } from '../modules/packages/propTypes';

class PackageDetailModal extends React.Component {
  componentWillUpdate() {
    this.prevPkg = this.props.pkg;
  }

  render() {
    const { isOpen, toggle } = this.props;
    const pkg = this.props.pkg || this.prevPkg;
    return (
      <Modal
        className="flight-packageDetailModal"
        isOpen={isOpen}
        size="lg"
        toggle={toggle}
      >
        <DetailModalCloseButton toggle={toggle} />
        { pkg ? <PackageDetailCard pkg={pkg} /> : <span /> }
      </Modal>
    );
  }
}

PackageDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  pkg: PropTypes.oneOfType([
    PropTypes.shape(packageDetailShape).isRequired,
    PropTypes.shape(packageSummaryShape).isRequired,
  ]),
  toggle: PropTypes.func,
};

export default PackageDetailModal;
