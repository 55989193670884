/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'flight-reactware';

import groupToIconSrc from '../utils/groupIcons';

const PackageGroupIcon = ({ group, size }) => (
  <Icon
    size={size}
    src={groupToIconSrc(group)}
  />
);

PackageGroupIcon.propTypes = {
  group: PropTypes.string.isRequired,
  size: PropTypes.any,
};

export default PackageGroupIcon;
