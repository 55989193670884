import { css } from 'styled-components';

export const styles = {
  Benchmarks: css`
    background-color: #fffcf9;
    background: repeating-linear-gradient(45deg,#fffcf9,#fffcf9 5px,#ffffff 5px,#ffffff 8px);
  `,
  Biochemistry: css`
    background-color: #fff9fc;
    background: repeating-linear-gradient(45deg,#fff9fc,#fff9fc 5px,#ffffff 5px,#ffffff 8px);
  `,
  Bioinformatics: css`
    background-color: #fafff6;
    background: repeating-linear-gradient(45deg,#fafff6,#fafff6 5px,#ffffff 5px,#ffffff 8px);
  `,
  Chemistry: css`
    background-color: #fcf9ff;
    background: repeating-linear-gradient(45deg,#fcf9ff,#fcf9ff 5px,#ffffff 5px,#ffffff 8px);
  `,
  'Fluid-Dynamics': css`
    background-color: #f9fffc;
    background: repeating-linear-gradient(45deg,#f9fffc,#f9fffc 5px,#ffffff 5px,#ffffff 8px);
  `,
  Geography: css`
    background-color: #f9fcff;
    background: repeating-linear-gradient(45deg,#f9fcff,#f9fcff 5px,#ffffff 5px,#ffffff 8px);
  `,
  Graphics: css`
    background-color: #fffcfc;
    background: repeating-linear-gradient(45deg,#fffcfc,#fffcfc 5px,#ffffff 5px,#ffffff 8px);
  `,
  Imaging: css`
    background-color: #fcfffc;
    background: repeating-linear-gradient(45deg,#fcfffc,#fcfffc 5px,#ffffff 5px,#ffffff 8px);
  `,
  Languages: css`
    background-color: #fcfcff;
    background: repeating-linear-gradient(45deg,#fcfcff,#fcfcff 5px,#ffffff 5px,#ffffff 8px);
  `,
  Tools: css`
    background-color: #fcfcfc;
    background: repeating-linear-gradient(45deg,#fcfcfc,#fcfcfc 5px,#ffffff 5px,#ffffff 8px);
  `,
  Libraries: css`
    background-color: #f9f9f9;
    background: repeating-linear-gradient(45deg,#f9f9f9,#f9f9f9 5px,#ffffff 5px,#ffffff 8px);
  `,
  Physics: css`
    background-color: #fcf9f9;
    background: repeating-linear-gradient(45deg,#fcf9f9,#fcf9f9 5px,#ffffff 5px,#ffffff 8px);
  `,
  Mathematics: css`
    background-color: #f9fcf9;
    background: repeating-linear-gradient(45deg,#f9fcf9,#f9fcf9 5px,#ffffff 5px,#ffffff 8px);
  `,
  MPI: css`
    background-color: #f9f9fc;
    background: repeating-linear-gradient(45deg,#f9f9fc,#f9f9fc 5px,#ffffff 5px,#ffffff 8px);
  `,
};
