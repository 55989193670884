/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

const MarkdownSection = ({ source, title, className }) => {
  const classes = classNames('flight-markdown-well', className);

  return (
    <div className={classes}>
      { title ? <h4>{title}</h4> : null }
      <ReactMarkdown
        className="card-text"
        escapeHtml
        source={source}
      />
    </div>
  );
};

MarkdownSection.propTypes = {
  className: PropTypes.any,  // eslint-disable-line react/forbid-prop-types
  source: PropTypes.string.isRequired,
  title: PropTypes.node,
};

export default MarkdownSection;
