import session from '../modules/session';

const logics = [
  ...session.logic,
];

export default (store) => {
  store.subscribe(() => {
    logics.forEach(logic => {
      logic(store.dispatch, store.getState);
    });
  });
};

