/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import invariant from 'invariant';

import 'react-perfect-scrollbar/dist/css/styles.css';

class Scrollbar extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.setScrollTopFromMouseWheelEvent =
      this.setScrollTopFromMouseWheelEvent.bind(this);
  }

  componentDidMount() {
    this.addAdditionalScrollListener();
  }

  componentWillUnmount() {
    this.removeAdditionalScrollListener();
  }

  getAdditionalScrollContainer() {
    const { includeScrollingInSelector } = this.props;
    if (includeScrollingInSelector == null) {
      return undefined;
    }
    const container = document.querySelector(includeScrollingInSelector);

    invariant(
      container,
      'Unable to find scrollListener element (%s)',
      includeScrollingInSelector,
    );

    return container;
  }

  getScrollableContainer() {
    // eslint-disable-next-line no-underscore-dangle
    return this.perfectScrollbar._container;
  }

  getScrollTop() {
    return this.getScrollableContainer().scrollTop;
  }

  setScrollTop(scrollTop) {
    this.perfectScrollbar.setScrollTop(scrollTop);
  }

  setScrollTopFromMouseWheelEvent(event) {
    const excludeEl = this.excludeScrollingInElement;
    if (excludeEl && excludeEl.contains(event.target)) {
      return;
    }

    const currentScrollTop = this.getScrollTop();
    const delta = event.wheelDelta || -event.detail;
    this.perfectScrollbar.setScrollTop(currentScrollTop - delta);
  }

  addAdditionalScrollListener() {
    const container = this.getAdditionalScrollContainer();
    if (container) {
      const { excludeScrollingInSelector } = this.props;
      if (excludeScrollingInSelector != null) {
        this.excludeScrollingInElement = document.querySelector(
          excludeScrollingInSelector,
        );
      }
      container.addEventListener(
        'mousewheel', this.setScrollTopFromMouseWheelEvent,
      );
    }
  }

  removeAdditionalScrollListener() {
    const container = this.getAdditionalScrollContainer();
    if (container) {
      container.removeEventListener(
        'mousewheel', this.setScrollTopFromMouseWheelEvent,
      );
    }
  }

  render() {
    return (
      <PerfectScrollbar ref={(ps) => { this.perfectScrollbar = ps; }}>
        {this.props.children}
      </PerfectScrollbar>
    );
  }
}

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  excludeScrollingInSelector: PropTypes.string,
  includeScrollingInSelector: PropTypes.string,
};

export default Scrollbar;
