/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';
import { pure } from 'recompose';
import styled from 'styled-components';
import { Styles, Theme } from 'flight-reactware';

import PackageGroupIcon from './PackageGroupIcon';
import { packageGroupingShape } from '../utils/propTypes';


const PackageGroupSelectListItem = styled(({
  active, className, id, name, setPackageGroupFilter,
}) => (
  <ListGroupItem
    active={active}
    className={className}
    onClick={() => setPackageGroupFilter(id)}
  >
    <PackageGroupIcon group={name} />
    <span
      className="packageGroupSelectList-item-text"
      title={name}
    >
      {name}
    </span>
  </ListGroupItem>
))`
  display: flex;
  padding-left: 15px;

  &.active {
    background-color: ${Theme.primary};
    border-color: ${Theme.primary};
  }

  .packageGroupSelectList-item-text {
      flex: 1;
      ${Styles.ellipsis()}
      margin-left: 10px;
  }

  .badge {
      margin: 1px 0 1px 4px;
  }
`;

PackageGroupSelectListItem.propTypes = {
  active: PropTypes.bool.isRequired,
  setPackageGroupFilter: PropTypes.func.isRequired,
  ...packageGroupingShape,
};

export default pure(PackageGroupSelectListItem);
