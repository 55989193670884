/*=============================================================================
 * Copyright (C) 2016-2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

const searchState = state => state.search;

// Search terms.
export const packageSearchTermSelector = state => searchState(state).packageSearchTerm;
export const depotSearchTermSelector = state => searchState(state).depotSearchTerm;
export const clusterSearchTermSelector = state => searchState(state).clusterSearchTerm;

// Filters.
export const packageGroupFilterSelector = state => searchState(state).packageGroupFilter;
