/*=============================================================================
 * Copyright (C) 2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cx from 'classnames';

const DetailModalCloseButton = styled(({ className, toggle }) => (
  <button
    aria-label="Close"
    className={cx(className, 'close')}
    onClick={toggle}
    type="button"
  >
    <span aria-hidden>x</span>
  </button>
))`
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 90;
  color: #000;
`;

DetailModalCloseButton.propTypes = {
  toggle: PropTypes.func,
};

export default DetailModalCloseButton;
