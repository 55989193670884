/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

import {
  CONNECT_ACCOUNT_MESSAGE_DISMISSED,
  RESET,
  WELCOME_MESSAGE_READ,
} from './actionTypes';

const initialState = {
  connectAccountMessageDismissed: false,
  welcomeMessageRead: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CONNECT_ACCOUNT_MESSAGE_DISMISSED:
      return { ...state, connectAccountMessageDismissed: action.payload };

    case RESET:
      // We don't reshow the welcome message, but we do potentially want to
      // redisplay the connect account message.
      return { ...state, connectAccountMessageDismissed: false };

    case WELCOME_MESSAGE_READ:
      return { ...state, welcomeMessageRead: action.payload };

    default:
      return state;
  }
}
