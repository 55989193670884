/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

import {
  INCREMENT_CURRENT_DATA_FRETCHING_STEP,
  INITIALIZATION_COMPLETED,
  INITIALIZATION_STARTED,
  SESSION_DATA_RETRIEVED,
  SET_TOTAL_DATA_FETCHING_STEPS,
} from './actionTypes';

export function initializationStarted() {
  return {
    type: INITIALIZATION_STARTED,
  };
}

export function initializationCompleted() {
  return {
    type: INITIALIZATION_COMPLETED,
  };
}

export function incrementCurrentDataFetchingStep() {
  return {
    type: INCREMENT_CURRENT_DATA_FRETCHING_STEP,
  };
}

export function setTotalDataFetchingSteps(steps) {
  return {
    type: SET_TOTAL_DATA_FETCHING_STEPS,
    payload: steps,
  };
}

export function sessionDataRetrieved() {
  return {
    type: SESSION_DATA_RETRIEVED,
  };
}
