import { auth } from 'flight-reactware';

import { initialize as initializeAction } from './actions';

let initialized = false;
function initializeLogic(dispatch) {
  if (!initialized) {
    initialized = true;
    dispatch(initializeAction());
  }
}

export default [
  initializeLogic,
  auth.logic.checkSessionExpiration,
];
