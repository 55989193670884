/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import PropTypes from 'prop-types';

export const packageSummaryShape = {
  id: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    group: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    repo: PropTypes.string.isRequired,
    summary: PropTypes.string, // .isRequired,
    title: PropTypes.string, // .isRequired,
  }),
};

export const packageDetailShape = {
  id: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    group: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    repo: PropTypes.string.isRequired,
    summary: PropTypes.string, // .isRequired,
    title: PropTypes.string, // .isRequired,
    version: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    description: PropTypes.string,  //.isRequired,
    changelog: PropTypes.string.isRequired,
    license: PropTypes.string.isRequired,
    url: PropTypes.string,
  }),
};
