/*=============================================================================
 * Copyright (C) 2016-2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

export const accountsState =
  state => (state.entities.accounts ? state.entities.accounts.data : {});
export const cdJobsState = state => (state.entities.continuousDeliveryJobs ?
  state.entities.continuousDeliveryJobs.data :
  {}
);
export const clustersState =
  state => (state.entities.clusters ? state.entities.clusters.data : {});

export const depotDefinitionsState =
  state => (state.entities.depotDefinitions ? state.entities.depotDefinitions.data : {});

export const flagsState =
  state => (state.entities.flags ? state.entities.flags.data : {});

export const packagesState =
  state => (state.entities.packages ? state.entities.packages.data : {});

export const packageIndexes =
  state => (state.entities.packages ? state.entities.packages.index : {});

export const uiState = state => state.ui;

export const onboardingState = state => state.onboarding;
