import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { ProductBar } from 'flight-reactware';

import items from '../modules/items';

const Page = ({ children, pageKey, title }) => {
  return (
    <div className="pageContainer">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ProductBar
        items={items}
        noaccount
        nosearch
        page={pageKey || title || ''}
        site={process.env.REACT_APP_SITE}
      />
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  pageKey: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Page;
