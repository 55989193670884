/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import classNames from 'classnames';

export const replaceWhitespace = string => string.replace(/\s/g, '-');

const packageCardModifierClasses = (pkg) => {
  const { group, packageType, repo } = pkg.attributes;

  return classNames({
    [`group-${replaceWhitespace(group)}`]: group,
    [`type-${replaceWhitespace(packageType)}`]: packageType,
    [`repo-${replaceWhitespace(repo)}`]: repo,
  });
};

export default packageCardModifierClasses;
