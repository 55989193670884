/*=============================================================================
 * Copyright (C) 2016-2017 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import _ from 'lodash';
import { createFilter } from 'react-search-input';
import { createSelector } from 'reselect';

import {
  depotDefinitionsState,
  packagesState,
} from './rawStateSelectors';
import {
  depotSearchTermSelector,
} from './searchTermSelectors';

function packagesForDepot(depot, allPackages) {
  if (depot == null) {
    return [];
  }
  if (depot.relationships.packages.data == null) {
    return [];
  }
  return depot.relationships.packages.data.reduce((accum, pkgLink) => {
    accum.push(allPackages[pkgLink.id]);
    return accum;
  }, []);
}

function depotDefinitionFromRouteSelector(state, props) {
  const depotId = props.routeParams.depotId;
  return depotDefinitionsState(state)[depotId];
}

const exportedDepotDefinitionsSelector = createSelector(
  depotDefinitionsState,

  depots => (
    _.chain(depots)
      .values()
      .filter(depot => depot.attributes.status !== 'unexported')
      .sortBy(depot => depot.attributes.title.toLowerCase())
      .value()
  ),
);

export const activeDepotSelector = createSelector(
  state => state.activeDepot.activeDepotId,
  depotDefinitionsState,

  (activeDepotId, depotDefinitions) => depotDefinitions[activeDepotId],
);

export const activeDepotPackagesSelector = createSelector(
  activeDepotSelector,
  packagesState,

  packagesForDepot,
);

export const depotAndPackagesFromRouteSelector = createSelector(
  depotDefinitionFromRouteSelector,
  packagesState,

  (depot, packages) => ({
    depot,
    packages: packagesForDepot(depot, packages),
  }),
);

export const filteredExportedDepotDefinitionsSelector = createSelector(
  exportedDepotDefinitionsSelector,
  depotSearchTermSelector,

  (unfilteredDepots, searchTerm) => {
    const searchProps = [
      'attributes.title',
      'attributes.summary',
      'attributes.description',
      'attributes.status',
    ];
    const depots = _.filter(
      unfilteredDepots,
      createFilter(searchTerm, searchProps),
    );

    return depots;
  },
);
