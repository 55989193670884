/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CardTitleBlock, DelaySpinner, jsonApi, showSpinnerUntil } from 'flight-reactware';
import { Card, CardBody, CardFooter, CardText } from 'reactstrap';
import styled from 'styled-components';

import Footer from './PackageDetailCardFooter';
import FooterIcons from './PackageCardFooterIcons';
import MarkdownSection from './MarkdownSection';
import packageCardModifierClasses, { replaceWhitespace } from '../utils/packageCardClasses';
import { styles } from '../utils/packageGroupStyles';
import { packageDetailShape, packageSummaryShape } from '../modules/packages/propTypes';

const HomePageSection = ({ url }) => (
  <div>
    <h4>Home page</h4>
    <p>
      Additional information can be found at{' '}
      <a
        href={url}
        rel="noopener noreferrer"
        target="_blank"
      >
        {url}
      </a>.
    </p>
  </div>
);

HomePageSection.propTypes = {
  url: PropTypes.string.isRequired,
};

const PackageDetailContent = ({ pkg }) => (
  <div>
    <MarkdownSection
      source={pkg.attributes.description}
      title="Description"
    />
    <h4>License</h4>
    <p>
      {pkg.attributes.license}
    </p>
    {pkg.attributes.url ? <HomePageSection url={pkg.attributes.url} /> : null}
  </div>
);

PackageDetailContent.propTypes = {
  pkg: PropTypes.oneOfType([
    PropTypes.shape(packageDetailShape).isRequired,
    PropTypes.shape(packageSummaryShape).isRequired,
  ]).isRequired,
};

const DelayedPackageDetailContent = showSpinnerUntil(
  ({ pkg }) => jsonApi.utils.getClientMeta(pkg).detailsLoaded,
  styled(DelaySpinner)`height: 240px; padding-top: 0;`,
)(
  PackageDetailContent,
);


const PackageDetailCard = styled(({ className, pkg }) => {
  const classes = cx(
    className,
    'flight-packageDetailCard',
    packageCardModifierClasses(pkg),
  );

  return (
    <Card className={classes}>
      <CardBody>
        <CardTitleBlock
          logoOnRight
          logoSrc={pkg.attributes.logoUrl}
          subtitle={pkg.attributes.longTitle}
          subtitleSize="x-large"
          title={pkg.attributes.shortTitle}
          titleSize="x-large"
        />
        <CardText>
          {pkg.attributes.summary}
        </CardText>
        <DelayedPackageDetailContent pkg={pkg} />
        <FooterIcons pkg={pkg} />
      </CardBody>
      <CardFooter>
        <Footer pkg={pkg} />
      </CardFooter>
    </Card>
  );
  /* eslint-disable indent */
})`
    &, .card-footer {
      ${(props) => styles[replaceWhitespace(props.pkg.attributes.group)]},
    }

    .card-body {
      padding: 25px;
    }
    .card-footer {
      background-color: #fff;
      text-align: center;
    }
    .card-icon--repo .fa {
      color: ${props => (
        props.pkg.attributes.repo === 'volatile' ? '#b37349' : '#418162'
      )};
    }
`;
/* eslint-enable indent */

PackageDetailCard.propTypes = {
  pkg: PropTypes.oneOfType([
    PropTypes.shape(packageDetailShape).isRequired,
    PropTypes.shape(packageSummaryShape).isRequired,
  ]).isRequired,
};

export default PackageDetailCard;
