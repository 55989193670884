/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/
import React from 'react';
import PropTypes from 'prop-types';
import { CardFooterIcon, CardFooterIcons } from 'flight-reactware';

import { packageSummaryShape } from '../modules/packages/propTypes';
import groupToIconSrc from '../utils/groupIcons';

const PackageCardFooterIcons = ({
  pkg: { attributes: { group } },
}) => (
  <CardFooterIcons>
    <CardFooterIcon
      src={groupToIconSrc(group)}
      text={group}
      tooltip={`Group ${group}`}
    />
  </CardFooterIcons>
);

PackageCardFooterIcons.propTypes = {
  pkg: PropTypes.shape(packageSummaryShape).isRequired,
};

export default PackageCardFooterIcons;
