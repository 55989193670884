/*=============================================================================
 * Copyright (C) 2016 Stephen F. Norledge and Alces Flight Ltd.
 *
 * This file is part of Alces Prime.
 *
 * All rights reserved, see LICENSE.txt.
 *===========================================================================*/

export const CLUSTER_SEARCH_TERM_SET = '@prime/search/CLUSTER_SEARCH_TERM_SET';
export const DEPOT_SEARCH_TERM_SET = '@prime/search/DEPOT_SEARCH_TERM_SET';
export const PACKAGE_GROUP_FILTER_SET = '@prime/search/PACKAGE_GROUP_FILTER_SET';
export const PACKAGE_SEARCH_TERM_SET = '@prime/search/PACKAGE_SEARCH_TERM_SET';
